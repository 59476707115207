import axios from 'axios';

export const http = axios.create({
    baseURL: process.env.REACT_APP_API_SYMFONY_URL,
});

export const httpApiPost = async (url: string, data: {}) => {
    const config = {headers: {'Content-Type': 'application/json'}};

    return axios.post(process.env.REACT_APP_API_SYMFONY_URL + url, data, config);
};

export const httpApiGet = async (url: string) => {
    const config = {headers: {'Content-Type': 'application/json'}};

    return axios.get(process.env.REACT_APP_API_SYMFONY_URL + url, config);
};
