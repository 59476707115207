export const handleEnum = (enumType: any, number: number) => {
    return Object.keys(enumType).filter((v) => isNaN(Number(v)))[number - 1].toString();
};

export const handleValue = (enumType: any, keys: any) => {
    return Object.keys(enumType).filter((v) => isNaN(Number(v))).indexOf(keys) + 1;
};

export const getValueByKey = (enumType: any, s: string | undefined, stringValue?: boolean): string | number => {
    let indexOfS: number | undefined;

    for (const [key, value] of Object.entries(enumType)) {
        if (key === s) {
            indexOfS = Number(value);
        }
    }

    if (indexOfS === undefined || indexOfS === 0) {
        return 0;
    }
    if (stringValue) {
        return String(indexOfS);
    } else {
        return Number(indexOfS);
    }
};
