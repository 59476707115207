import { Box, Link } from '@mui/material';
import React, { FunctionComponent } from 'react';
import theme from '../theme';

type buttonLinkMutacProps = {
    label: string,
    url: string,
    blueLight?: boolean,
};
const ButtonLinkMutac: FunctionComponent<buttonLinkMutacProps> = (props: buttonLinkMutacProps) => {
    let style: any;

    if (props.blueLight) {
        style = {
            borderRadius: 20,
            px: 4,
            py: 1,
            backgroundColor: theme.palette.text.secondary,
            color: theme.palette.primary.main,
            ':hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
            }
        };
    } else {
        style = {
            borderRadius: 20,
            px: 4,
            py: 1,
            fontSize: 21,
        };
    }


    return (
        <Box sx={{textAlign: 'center'}}>
            <Link
                sx={style}
                href={props.url}
            >
                {props.label}
            </Link>
        </Box>
    );
};

export default ButtonLinkMutac;
