import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import theme from '../theme';

type WidgetProps = {
    text: string,
    type?: boolean,
    center?: boolean,
};

const H3: FunctionComponent<WidgetProps> = (props: WidgetProps) => {
    return (
        <Typography
            dangerouslySetInnerHTML={{__html: props.text}}
            sx={{
                verticalAlign: 'center',
                color: props.type ? theme.palette.error.main : theme.palette.primary.main,
                fontWeight: props.type ? 'bold' : 'normal',
                ml: 1,
                textAlign: props.center ? 'center' : 'left',
            }}
            variant="h3"
        />
    );
};

export default H3;



