import { createTheme } from '@mui/material';
import 'typeface-raleway';

const mutacBLUE: string = '#011C77';
const mutacBLUElight: string = '#4D609F';
const mutacBLUElightButton: string = '#82B5DC';
const mutacBLUEdark: string = '#000D3B';
const mutacORANGE: string = '#F24F03';
const mutacORANGElight: string = '#F47235';
const mutacORANGEdark: string = '#C13F02';
const mutacWhite: string = '#FFF';

const theme = createTheme({
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: mutacORANGElight
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    color: mutacWhite,
                    backgroundColor: mutacBLUE,
                    boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.1)',
                    ':hover': {
                        backgroundColor: mutacBLUElightButton,
                        color: mutacBLUE,
                    }
                },
                text: {
                    ':hover': {
                        backgroundColor: mutacBLUE,
                        color: mutacWhite,
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: mutacWhite
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    fontSize: '1rem',
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    color: mutacBLUE,
                    backgroundColor: mutacWhite,
                    ':hover': {
                        textDecoration: 'none',
                        backgroundColor: mutacBLUE,
                        color: mutacWhite,
                        border: '1px solid white',
                    }
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    alignItems: 'stretch',
                    boxShadow: '0px 2px 1px rgba(255, 255, 255, 0.5)',
                    color: mutacWhite,
                    paddingBottom: 0,
                    paddingTop: 0,
                    fontSize: '1.2rem',
                    '@media (min-width:600px)': {
                        fontSize: '1.5rem',
                        marginTop: '1rem',
                        paddingLeft: '0.75rem',
                    },
                },
                icon: {
                    fill: mutacWhite
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    minWidth: '-webkit-fill-available',
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: mutacBLUE,
                    fontSize: '1.2rem',
                    '@media (min-width:600px)': {
                        fontSize: '1.5rem',
                    },
                }
            }
        },
        MuiRating: {
            styleOverrides: {
                iconFilled: {
                    color: mutacWhite
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    color: mutacBLUE,
                    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.5)',
                    fontSize: '1rem',
                    textAlign: 'center',
                    fontWeight: 'bold'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    color: mutacBLUE,
                    textAlign: 'center'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    width: '100px',
                    height: '100px'
                }
            }
        }
    },
    palette: {
        mode: 'light',
        error: {
            main: mutacORANGE,
            light: mutacORANGElight,
            dark: mutacORANGEdark
        },
        info: {
            main: mutacBLUE,
            light: mutacBLUElight,
            dark: mutacBLUEdark
        },
        text: {
            primary: mutacORANGE,
            secondary: mutacBLUE
        },
        primary: {
            main: mutacWhite,
        },
        secondary: {
            main: mutacBLUElightButton,
        }
    },
    typography: {
        fontFamily: [
            'Nunito',
            'Raleway',
            'Roboto',
            'Arial'
        ].join(','),
        h1: {
            fontFamily: 'Raleway',
            fontSize: '2.5rem',
            '@media (min-width:600px)': {
                fontSize: '3.5rem',
            },
            color: mutacWhite,
            textAlign: 'center',
            marginTop: '20px',
        },
        h2: {
            fontFamily: 'Raleway',
            fontSize: '1.5rem',
            '@media (min-width:600px)': {
                fontSize: '2.5rem',
            },
            color: mutacWhite,
            textAlign: 'center',
            marginTop: '0px',
            marginBottom: '40px',
        },
        h3: {
            fontFamily: 'Raleway',
            fontSize: '1.25rem',
            '@media (min-width:600px)': {
                fontSize: '1.5rem',
            },
            color: mutacWhite,
            textAlign: 'center',
            marginTop: '12px',
            marginBottom: '12px',
        },
        h6: {
            fontFamily: 'Nunito',
            fontSize: '1.5rem',
            color: mutacWhite,
            margin: '0.313rem',
            display: 'inline-block'
        },
        caption: {
            color: mutacWhite,
            fontSize: '1.1rem'
        },
    },
});

export default theme;