import { Box, Button, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import H2 from './H2';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import H1 from './H1';
import { useTranslation } from 'react-i18next';
import { previous } from '../features/navigation/navigationSlice';
import { KycState } from '../features/kyc/kycSlice';

type WidgetProps = {
    text: string,
    variant?: string,
    handleAction?: any,
    style?: object,
    subtitle?: string,
};

const Titlebar: FunctionComponent<WidgetProps> = (props: WidgetProps) => {

    const {step, subStep} = useAppSelector((state) => state.navigation);
    const {t} = useTranslation();
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const firstScreen: boolean = (step === 1 && subStep === 1) || kyc.quotationUpload === undefined ? true : kyc.quotationUpload;
    const variant: string = props.variant || 'H1';

    const previousStep = () => {
        if (props.handleAction) {
            dispatch(props.handleAction);
        } else {
            dispatch(previous());
        }
    };

    return (
        <Box display={'flex'} mt={3} justifyContent="space-between">
            <Button
                onClick={previousStep}
                sx={{
                    flex: 1,
                    visibility: firstScreen ? 'hidden' : 'visible',
                    justifyContent: 'flex-start',
                    height: 'fit-content'
                }}
            >
                <NavigateBeforeIcon fontSize="large" color="primary"/>
                <span>{t('translation:placeholderForm.back')}</span>
            </Button>
            <Box>
                {variant === 'H1' && <H1 style={props.style}>{props.text}</H1>}
                {variant === 'H2' && <H2 style={props.style}>{props.text}</H2>}
                {props.subtitle && <Typography variant={'h2'}>{props.subtitle}</Typography>}
            </Box>
            <Button
                sx={{
                    flex: 1,
                    visibility: 'hidden'
                }}
            >
                <span>{t('translation:placeholderForm.next')}</span>
                <NavigateNextIcon sx={{flex: 1}} fontSize="large" color="secondary"/>
            </Button>
        </Box>
    );
};

export default Titlebar;
