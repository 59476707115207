import { Checkbox, FormControlLabel } from '@mui/material';
import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import theme from '../theme';

type WidgetProps = {
    id: string,
    onClick: MouseEventHandler,
    children?: ReactNode,
    value?: boolean,
    name?: string,
};

const CheckboxWidget: FunctionComponent<WidgetProps> = (props: WidgetProps) => {
    return (
        <FormControlLabel
            id={props.id}
            onClick={props.onClick}
            control={
                <Checkbox
                    name={props.name}
                    checked={props.value}
                    sx={{minWidth: '6.25rem', color: theme.palette.text.primary}}
                    size="medium"
                />
            }
            label={props.children}
        />
    );
};

export default CheckboxWidget;



