import React, { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { isConcerned, KycState, updateWhoIsConcerned, WhatIWant } from '../../features/kyc/kycSlice';
import { Box, Grid } from '@mui/material';
import { next, nextWithSkip, previous } from '../../features/navigation/navigationSlice';
import ButtonLinkMutac from '../ButtonLinkMutac';
import RequirementsForm from './RequirementsForm';
import ButtonWithIcon from '../ButtonWithIcon';
import ButtonWidget from '../ButtonWidget';
import { useTranslation } from 'react-i18next';
import H2 from '../H2';
import H3 from '../H3';
import Titlebar from '../Titlebar';
import theme from '../../theme';

const RequirementsPage: FunctionComponent = () => {

    const {subStep} = useAppSelector((state) => state.navigation);
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const whoIsConcernedHandle = (whoIs: number) => {
        dispatch(updateWhoIsConcerned(whoIs));
        whoIs === 5 ? dispatch(next()) : dispatch(nextWithSkip());
    };

    return (
        <Box style={{margin: 20}}>
            {/***************************************************************************************************************** */}
            {subStep === 1 &&
              <Box>
                <Titlebar text={t('translation:requirements.2.title')} style={{marginBottom: 15}}/>
                <Box>
                  <Grid container sx={{maxWidth: '1400px', display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                    <Grid item xs={4} md={3} padding={1} sx={{display: 'flex', justifyContent: 'center'}}>
                      <ButtonWithIcon
                        icon="PermIdentityIcon"
                        label="Moi uniquement"
                        url="assets/onlyMe.jpg"
                        onSelect={() => whoIsConcernedHandle(3)}
                        selected={kyc.whoIsConcerned === 3}
                      />
                    </Grid>
                      {/*//TODO*/}
                      {/*<Grid item xs={4} md={3} padding={1} sx={{display: 'flex', justifyContent: 'center'}}>*/}
                      {/*  <ButtonWithIcon*/}
                      {/*    icon="SpouseIcon"*/}
                      {/*    label="Mon couple"*/}
                      {/*    url="assets/couple.jpg"*/}
                      {/*    onSelect={() => whoIsConcernedHandle(4)}*/}
                      {/*    selected={kyc.whoIsConcerned === 4}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                    <Grid item xs={4} md={3} padding={1} sx={{display: 'flex', justifyContent: 'center'}}>
                      <ButtonWithIcon
                        icon="PersonneTutelle"
                        label="Personne sous tutelle ou majeur protégé"
                        url="assets/accompagnement.jpg"
                        onSelect={() => whoIsConcernedHandle(5)}
                        selected={kyc.whoIsConcerned === 5}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{marginTop: 3}}>
                  <Grid item padding={1} xs={4} md={3} className="uk-text-center" sx={{
                      verticalAlign: 'middle',
                      backgroundColor: 'background.paper',
                      borderRadius: 5,
                      color: theme.palette.text.secondary,
                      maxWidth: '17%',
                      margin: 'auto',
                  }}
                  >
                    <p style={{
                        color: theme.palette.text.primary,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        paddingTop: 0,
                        paddingBottom: 0
                    }}
                    >
                      <span
                        style={{fontSize: '2em'}}>{t('translation:requirements.2.pourcent')}</span>{t('translation:requirements.2.reduction')}
                    </p>
                    <p style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontSize: '1.35em',
                    }}>{t('translation:requirements.2.condition')}</p>
                  </Grid>
                </Box>
              </Box>
            }
            {/*********************************************************** ****************************************************** */}
            {
                subStep === 2 && kyc.whoIsConcerned === isConcerned.ProtectedPerson &&
              <Box>
                <Titlebar text={t('translation:requirements.3.title')} style={{marginBottom: 10}}/>
                <Box mx={25} marginBottom={'4rem'}>
                  <H2>{t('translation:requirements.3.p')}</H2>
                </Box>
                <ButtonLinkMutac label={t('translation:button-rdv')}
                                 url={t('translation:url-mutac-contact')}/>
              </Box>
            }
            {/***************************************************************************************************************** */}
            {
                subStep === 3 &&
              <Box>
                <Titlebar style={{lineHeight: 1.5, marginBottom: 7}} text={t('translation:requirements.4.title')}/>
                <RequirementsForm/>
              </Box>
            }
            {/*********************************************************** ****************************************************** */}
            {
                subStep === 4 && kyc.whatIWant !== WhatIWant[`${Number(WhatIWant.Financing)}`] &&
              <Box>
                <Titlebar text={t('translation:requirements.5.title')} style={{marginBottom: 15}}/>
                <Box sx={{width: '85%', margin: 'auto'}}>
                  <Box sx={{marginBottom: 15}}>
                    <H3 center text={t('translation:requirements.5.p')}/>
                  </Box>

                  <Box sx={{mt: 5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <ButtonWidget
                      label={t('translation:requirements.5.button-change')}
                      handleAction={() => dispatch(previous())}
                      py={0.8} mx={4} fontSize={1.1}/>
                    <ButtonLinkMutac
                      label={t('translation:button-rdv')}
                      url={t('translation:url-mutac-contact')}
                    />
                  </Box>
                </Box>
              </Box>
            }

        </Box>
    );
};

export default RequirementsPage;
