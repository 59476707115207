import { Typography } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';

type WidgetProps = {
    children: ReactNode,
    marginBottom?: string,
    style?: object,
};

const H1: FunctionComponent<WidgetProps> = (props: WidgetProps) => {
    const styleH1 = {verticalAlign: 'center', marginBottom: props.marginBottom ?? '2.5rem'};
    const styleAll = Object.assign({}, styleH1, props.style);

    return (
        <Typography
            sx={styleAll}
            variant="h1"
            dangerouslySetInnerHTML={{__html: `${props.children}`}}
        ></Typography>
    );
};

export default H1;



