import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Box, Button, Tooltip } from '@mui/material';
import { next } from '../../features/navigation/navigationSlice';
import ButtonWidget from '../ButtonWidget';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import H6 from '../H6';
import Loader from '../Loader';
import { KycState, updateAgreementMembership } from '../../features/kyc/kycSlice';
import { getFiles, getGeneralFiles } from '../../utils/files';
import CheckboxWidget from '../CheckboxWidget';
import theme from '../../theme';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { toast, ToastContainer } from 'react-toastify';
import { getActiveOffer, OffersState } from '../../features/offers/offersSlice';
import axios from 'axios';

type formContractProps = { keyI18n: string };
const FormContract: FunctionComponent<formContractProps> = (props: formContractProps) => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const {t} = useTranslation();
    const {step} = useAppSelector((state) => state.navigation);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const offers: OffersState = useAppSelector((state) => state.offers);

    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    };

    const printDocument = (doc: any, key: number) => {
        key < 3 && doc.print();
    };

    const download = async (option: string, key: number) => {
        let nameFile: string;
        let link: any;

        switch (key){
            case 0:
                nameFile = t('translation:contract.1.p1');
                break;
            case 1:
                nameFile = t('translation:contract.1.p2');
                break;
            case 2:
                nameFile = t('translation:contract.1.p3');
                break;
            case 3:
                nameFile = t('translation:contract.1.p4');
                break;
            default:
                nameFile = t('translation:contract.1.p5');
        }

        try {
            setLoading(true);
            let url, res;

            switch (key){
                case 0:
                    url = await dispatch(getFiles(kyc));
                    break;
                default:
                    url = await dispatch(getGeneralFiles(kyc, key, getActiveOffer(offers)));
                    break;
            }
            if (option === t('translation:contract.button-q-read.download')) {
                res = await axios.get(url, {responseType: 'blob'});
                const blob = new Blob([res.data], {type: 'application/pdf'});
                url = window.URL.createObjectURL(blob);
            }
            setLoading(false);
            link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            link.setAttribute('download', `${nameFile}.pdf`);
            document.body.appendChild(link);
            option === t('translation:contract.button-q-read.download') && link.click();
            option === t('translation:contract.button-q-read.read') && window.open(url, '_blank');
            option === t('translation:contract.button-q-read.print') && printDocument(window.open(url, '_blank'), key);
        } catch (error) {
            setLoading(false);
            toast.warning(t('translation:errors.failed-download'), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    return (
        <>
            <ToastContainer/>
            {loading ?
                (
                    <>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5rem'}}>
                            <Loader size={'10rem'} color={'light'}/>
                        </Box>
                    </>
                ) : (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginLeft: step === 7 ? '5rem' : '15.625rem',
                        marginTop: step === 5 ? '' : '6rem',
                    }}>
                        {Object.keys(i18next.t(props.keyI18n, {returnObjects: true})).map((item, key) => (
                            <Box
                                sx={{
                                    marginTop: '0.4rem',
                                    marginBottom: '0.4rem',
                                }}
                                key={key}
                            >
                                <H6 style={{fontSize: 23}} key={t(item)}>
                                    {t(`${props.keyI18n}.${item}`)}
                                </H6>
                                <Tooltip title={t('translation:contract.button-q-read.read')}>
                                    <Button variant="text"
                                            onClick={() => {
                                                download(t('translation:contract.button-q-read.read'), key);
                                            }}
                                            sx={{color: theme.palette.info.light, alignItems: 'stretch',}}>
                                        <SearchIcon fontSize="large" sx={{mb: 1}}/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title={t('translation:contract.button-q-read.print')}>
                                    <Button variant="text"
                                            onClick={(e) => download(t('translation:contract.button-q-read.print'), key)}
                                            sx={{color: theme.palette.info.light, alignItems: 'stretch', fontSize: 32}}>
                                        <PrintIcon fontSize="inherit" sx={{mb: 1}}/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title={t('translation:contract.button-q-read.download')}>
                                    <Button variant="text"
                                            onClick={() => download(t('translation:contract.button-q-read.download'), key)}
                                            sx={{color: theme.palette.info.light, alignItems: 'stretch',}}>
                                        <DownloadIcon fontSize="large" sx={{mb: 1.04}}/>
                                    </Button>
                                </Tooltip>
                            </Box>
                        ))}
                    </Box>
                )
            }

            {step === 5 && !loading &&
              <Box mx={20} my={1}>
                <CheckboxWidget
                  id={'checkbox-contract'}
                  onClick={() => {
                      dispatch(updateAgreementMembership({agreementFormContract: !kyc.agreementFormContract}));
                  }}
                  value={kyc.agreementFormContract}
                >
                  <H6 style={{fontSize: 20}}>{t('translation:contract.checkbox')}</H6>
                </CheckboxWidget>
                  {kyc.agreementFormContract &&
                    <Box>
                      <ButtonWidget fontSize={1} my={0.5} label={t('translation:contract.button-subscribe')}
                                    handleAction={() => dispatch(next())}/>
                    </Box>
                  }
              </Box>
            }
        </>
    );
};

export default FormContract;
