import { http, httpApiPost } from './http.js';
import { KycState } from '../features/kyc/kycSlice';
import { Offer } from '../features/offers/offersSlice';

export const getFiles = (kyc: KycState) => async () => {
    return `${process.env.REACT_APP_API_SYMFONY_URL}/get-file-by-quotation?guidEspaceProspect=${kyc.folderGuidToQuotation}`;
};

export const saveFiles = (kyc: KycState, data: any, key: number) => async () => {
    let filename: string | undefined;
    switch (key){
        case 4:
            filename = 'Bulletin d\'adhésion';
            break;
        case 5:
            filename = 'Carte d\'identité';
            break;
        case 6:
            filename = 'Rib';
    }

    let formData: any = new FormData();
    formData.append('typeDocumentId', key);
    formData.append('selligentDc0Nrid', null);
    formData.append('guidEspaceProspect', kyc.folderGuidToQuotation);
    formData.append('idAccount', kyc.idAccount);
    formData.append('quotationNrid', kyc.quotationNrid);
    formData.append('email', kyc.email);
    formData.append('fullname', kyc.lastname?.toUpperCase() + ' ' + kyc.firstname?.toUpperCase());
    formData.append('filename', filename);
    formData.append('type', data.type.split('/')[1]);

    await getBase64(data)
        .then(result => {
            // @ts-ignore
            formData.append('File', result.split(`data:${data.type};base64,`)[1]);
        });

    return http.post('/save-file-from-tunnel', formData, {headers: {'Content-Type': 'multipart/form-data'}});
};

export const postGetFileList = (folderGuidToQuotation: string) => async () => {
    return httpApiPost(
        '/get-files-list',
        {
            'selligentDc0Nrid': 0,
            'guidEspaceProspect': folderGuidToQuotation,
        },
    );
};

export const getGeneralFiles = (kyc: KycState, key: number, offer: Offer) => async () => {
    let nameDocument: string | undefined;
    let typeDocument: string | undefined;

    switch (key){
        case 1:
            nameDocument = 'information_sheet';
            break;
        case 2:
            nameDocument = 'newsletter_membership';
            break;
        case 3:
            nameDocument = 'advice_sheet';
            break;
        default:
            nameDocument = 'recommendation_sheet';
    }

    switch (offer.name){
        case 'MUTAC Capital Obsèques':
            typeDocument = 'MCO';
            break;
        case 'Mutac Epargne Obsèques':
            typeDocument = 'MEO';
            break;
        case 'Public Obsèques':
            typeDocument = 'PO';
            break;
        default:
            typeDocument = 'MOEV';
    }

    return `${process.env.REACT_APP_API_SYMFONY_URL}/get-file?name=${nameDocument}&type=${typeDocument}`;
};

export const sessionMail = (kyc: KycState, filesNameSend: any) => async () => {
    return httpApiPost(
        '/session-mail',
        {
            'idAccount': kyc.idAccount,
            'guidEspaceProspect': kyc.folderGuidToQuotation,
            'quotationNrid': kyc.quotationNrid,
            'email': kyc.email,
            'fullname': kyc.lastname?.toUpperCase() + ' ' + kyc.firstname?.toUpperCase(),
            'filesNameSend': filesNameSend,
        },
    );
};

const getBase64 = (file: any) => {
    return new Promise(resolve => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
};
