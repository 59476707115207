import { Typography } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';

type WidgetProps = {
    children: ReactNode,
    mb?: number,
    style?: object,
};

const H2: FunctionComponent<WidgetProps> = (props: WidgetProps) => {
    const styleH1 = {marginBottom: props.mb ?? 8};
    const styleAll = Object.assign({}, styleH1, props.style);

    return (
        <Typography
            variant="h2" mx={5}
            sx={styleAll}
            dangerouslySetInnerHTML={{__html: `${props.children}`}}
        />
    );
};

export default H2;



