import React from 'react';
import { useTranslation } from 'react-i18next';
import H6 from '../H6';
import { Box } from '@mui/material';
import ButtonLink from '../ButtonLinkMutac';
import H1 from '../H1';

const Resume = () => {
    const {t} = useTranslation();

    return (
        <>
            <H1>{t('translation:contract.4.title')}</H1>
            <Box display="grid" alignItems="center" mx={'12.5rem'}>
                <Box my={'8rem'}>
                    <H6>{t('translation:contract.4.p1')}</H6>
                    <H6>{t('translation:contract.4.p2')}</H6>
                    <H6>{t('translation:contract.4.p3')}</H6>
                </Box>
                <Box mt={4}>
                    <ButtonLink label={t('translation:redirection')} url={t('translation:url-mutac')}/>
                </Box>

            </Box>
        </>
    );
};

export default Resume;
