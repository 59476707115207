import React, { FunctionComponent, MouseEventHandler } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import theme from '../theme';
import PersonIcon from '@mui/icons-material/Person';
import WcIcon from '@mui/icons-material/Wc';
import GroupIcon from '@mui/icons-material/Group';

let colorButton = theme.palette.primary.main;

const ImageButton = styled(ButtonBase)(() => ({
    position: 'relative',
    borderRadius: 50,
    display: 'block',
    [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        height: 100
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.4
        },
        '& .MuiImageMarked-root': {
            opacity: 0
        }
    }
}));

const ImageBackdrop = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.1,
    borderRadius: 20,
    transition: theme.transitions.create('opacity')
}));

type ButtonWithIconProps = { label: string, url: string, onSelect: MouseEventHandler, selected: boolean, icon: string };
const ButtonWithIcon: FunctionComponent<ButtonWithIconProps> = (props: ButtonWithIconProps) => {
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: 310,
                    px: 0.5,
                    py: 0.5,
                    borderRadius: 5,
                    border: 1,
                    borderColor: theme.palette.primary.main,
                    '&:hover': {color: theme.palette.text.secondary, backgroundColor: colorButton},
                    color: props.selected ? theme.palette.text.secondary : theme.palette.primary.main,
                    backgroundColor: props.selected ? theme.palette.primary.main : theme.palette.text.secondary,
                }}
            >
                <ImageButton
                    focusRipple
                    key={props.label}
                    style={{
                        width: 310,
                        height: 210,
                        borderRadius: 20,
                        fontSize: 50,
                    }}
                    onClick={props.onSelect}
                >
                    <ImageBackdrop className="MuiImageBackdrop-root"/>
                    {props.icon === 'PermIdentityIcon' &&
                      <PersonIcon fontSize="inherit"/>
                    }
                    {props.icon === 'SpouseIcon' &&
                      <div>
                        <WcIcon fontSize="inherit"/>
                      </div>
                    }
                    {props.icon === 'PersonneTutelle' &&
                      <  GroupIcon fontSize="inherit"/>
                    }
                    <Typography
                        sx={{
                            position: 'relative',
                            p: 2,
                            fontWeight: 'bold',
                            fontSize: '2rem',
                        }}
                    >
                        {props.label}
                    </Typography>
                </ImageButton>
            </Box>
        </div>
    );
};

export default ButtonWithIcon;
