import { Box, ButtonGroup, styled } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    Deferred,
    Duration,
    KycState,
    Payment,
    updatePayment,
    updateRequirements,
    WhatIWant
} from '../../features/kyc/kycSlice';
import { gotoStepAndSubStep } from '../../features/navigation/navigationSlice';
import ButtonWidget from '../ButtonWidget';
import InfoIconWidget from '../InfoIconWidget';
import Typography from '@mui/material/Typography';
import CustomModal from '../CustomModal';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import H6 from '../H6';
import SelectWidget from '../SelectWidget';
import { getValueByKey, handleEnum, handleValue } from '../../utils/enum';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    color: theme.typography.caption.color,
    marginLeft: 250,
});

const RequirementsForm: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    /* Help Modal : local state management */
    const [open, setOpen] = useState<number>();
    const handleModal = (index: number) => setOpen(open === index ? undefined : index);
    const capitals: number[] = [];
    const whatIWantValues: string[] = [];
    const paymentValues: string[] = [];
    const durationValues: string[] = [];
    const deferredValues: string[] = [];
    const dateDuJour: Date = new Date();

    useEffect(() => {
        // Value fix by client after recette
        dispatch(updateRequirements({whatIWant: 'Financing'}));
        dispatch(updateRequirements({deferred: 'Yes'}));
    }, []);

    for (let i = 1; i < 13; i++) {
        capitals.push(i * 1000);
    }

    //Defined options for selectors
    for (let i = 1; i <= Math.round(Object.values(WhatIWant).length / 2); i++) {
        whatIWantValues.push(t(`translation:requirements.4.form.q1.choice${i}`));
    }

    for (let i = 1; i <= Math.round(Object.values(Payment).length / 2); i++) {
        paymentValues.push(t(`translation:requirements.4.form.q3.choice${i}`));
    }
    for (let i = 1; i <= 4; i++) {
        durationValues.push(t('translation:requirements.4.form.q4.years', {value: i * 5}));
    }
    durationValues.push(t('translation:requirements.4.form.q4.unlimited'));
    for (let i = 1; i <= Math.round(Object.values(Deferred).length / 2); i++) {
        deferredValues.push(t(`translation:requirements.4.form.q5.choice${i}`));
    }

    const handleNextStep = () => {
        let singlePayment: string;

        if (getValueByKey(Payment, kyc.payment) === Payment.Single && kyc.capital) {
            switch (true){
                case kyc.capital <= 3000:
                    singlePayment = '37';
                    break;
                case kyc.capital <= 5000 && kyc.capital > 3000:
                    singlePayment = '38';
                    break;
                default:
                    singlePayment = '39';
            }
            dispatch(updateRequirements({singlePayment: singlePayment}));
            dispatch(updatePayment({isRecurringPayment: false}));
        }

        if ((getValueByKey(Duration, kyc.duration) !== Duration.Unlimited && kyc.duration && kyc.capital) ||
            (getValueByKey(Payment, kyc.payment) === Payment.Single && kyc.capital)) {

            switch (handleValue(Payment, kyc.payment)){
                case 2:
                    dispatch(updateRequirements({
                        durationNumber: handleValue(Duration, kyc.duration) * 5 * 12
                    }));
                    break;
                case 3:
                    dispatch(updateRequirements({
                        durationNumber: handleValue(Duration, kyc.duration) * 5
                    }));
                    break;
                default :
                    dispatch(updateRequirements({
                        durationNumber: 1
                    }));
            }
        }

        if (kyc.capital && kyc.capital < kyc.averageCost! + 1000 && kyc.whatIWant === 'Financing') {
            setOpen(2);

            return;
        }

        dispatch(gotoStepAndSubStep({step: 4, subStep: 1}));
    };

    const validNextStep = () => {
        return (kyc.whatIWant !== undefined &&
                kyc.capital !== undefined &&
                kyc.capital &&
                kyc.payment &&
                getValueByKey(Payment, kyc.payment) === Payment.Single &&
                kyc.deferred !== undefined) ||
            (kyc.whatIWant !== undefined &&
                kyc.capital !== undefined &&
                kyc.capital &&
                kyc.payment &&
                getValueByKey(Payment, kyc.payment) !== Payment.Single &&
                kyc.duration !== undefined &&
                kyc.deferred !== undefined);
    };

    return (
        <div>
            <BoxFormat style={{marginLeft: 185, zIndex: 1}} my={3}>
                <InfoIconWidget size="large" handleAction={() => handleModal(5)}/>
                <H6>{t('translation:requirements.4.form.q2.label')}</H6>
                <SelectWidget
                    id={'capital'}
                    value={kyc.capital || ''}
                    onChange={(event) => {
                        dispatch(updateRequirements({capital: Number(event.target.value)}));
                    }}
                    object={capitals}
                    isCurrency
                    isValue
                />
            </BoxFormat>
            <BoxFormat style={{marginLeft: 185, zIndex: 1}} my={3}>
                <InfoIconWidget size="large" handleAction={() => handleModal(3)}/>
                <H6>{t('translation:requirements.4.form.q3.label')}</H6>
                <SelectWidget
                    id={'payment'}
                    value={handleValue(Payment, kyc.payment) || ''}
                    onChange={(event) => {
                        dispatch(updateRequirements({payment: handleEnum(Payment, event.target.value)}));
                    }}
                    object={paymentValues}
                />
            </BoxFormat>
            {kyc.payment && getValueByKey(Payment, kyc.payment) !== Payment.Single &&
              <BoxFormat style={{marginLeft: 185, zIndex: 10}} my={3}>
                <InfoIconWidget size="large" handleAction={() => handleModal(4)}/>
                <H6>{t('translation:requirements.4.form.q4.label')}</H6>
                <SelectWidget
                  id={'duration'}
                  value={handleValue(Duration, kyc.duration) || ''}
                  onChange={(event) => {
                      dispatch(updateRequirements({
                          duration: handleEnum(Duration, event.target.value),
                          durationNumber: event.target.value * 5
                      }));
                  }}
                  object={durationValues}
                />
              </BoxFormat>
            }

            {validNextStep() &&
              <ButtonWidget
                mt={kyc.payment && getValueByKey(Payment, kyc.payment) !== Payment.Single ? 20 : 30}
                my={3}
                label={t('translation:continue')}
                handleAction={() => handleNextStep()}
              />
            }

            <CustomModal open={open !== undefined} handleModal={() => setOpen(undefined)}>
                {open === 2 &&
                  <div>
                    <Typography id="modal-modal-description"
                                sx={{
                                    mt: 2,
                                    textAlign: 'center',
                                    fontSize: '1.3rem',
                                    color: theme.palette.text.secondary
                                }}>
                        {t('translation:requirements.4.form.q2.modal.p1', {
                            year: dateDuJour.getFullYear() - 1,
                            cost: kyc.averageCost
                        })}
                    </Typography>
                    <Typography
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {t('translation:requirements.4.form.q2.modal.p2')}
                    </Typography>
                    <Typography
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {t('translation:requirements.4.form.q2.modal.p3')}
                    </Typography>
                    <Typography
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {t('translation:requirements.4.form.q2.modal.p4')}
                    </Typography>
                    <BoxFormat sx={{marginLeft: 0, justifyContent: 'center'}}>
                      <ButtonGroup sx={{mt: 2}}>
                        <ButtonWidget
                          label={t('translation:requirements.4.form.q2.modal.button-update')}
                          handleAction={() => setOpen(undefined)}
                          py={0.2} mx={1}
                          fontSize={1.2}
                        />
                        <ButtonWidget
                          label={t('translation:requirements.4.form.q2.modal.button-keep')}
                          handleAction={() => dispatch(gotoStepAndSubStep({step: 4, subStep: 1}))}
                          py={0.2} mx={1}
                          fontSize={1.2}
                        />
                      </ButtonGroup>
                    </BoxFormat>
                  </div>
                }
                {open === 3 &&
                  <div>
                    <Typography id="modal-modal-description"
                                sx={{
                                    mt: 2,
                                    textAlign: 'center',
                                    fontSize: '1.3rem',
                                    color: theme.palette.text.secondary
                                }}>
                        {t('translation:requirements.4.form.q3.modal.p1')}
                    </Typography>
                    <Typography
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {t('translation:requirements.4.form.q3.modal.p2')}
                    </Typography>
                    <Typography
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {t('translation:requirements.4.form.q3.modal.p3')}
                    </Typography>
                    <ButtonWidget
                      label={t('translation:requirements.4.form.q3.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
                {open === 4 &&
                  <div>
                    <Typography
                      id="modal-modal-description"
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                    >
                        {t('translation:requirements.4.form.q4.modal.p1')}
                    </Typography>
                    <Typography
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {t('translation:requirements.4.form.q4.modal.p2')}
                    </Typography>
                    <ButtonWidget
                      label={t('translation:requirements.4.form.q4.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
                {open === 5 &&
                  <div>
                    <Typography
                      id="modal-modal-description"
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                      dangerouslySetInnerHTML={{__html: `${t('translation:requirements.4.form.q2.modal-info.p')}`}}
                    />
                    <ButtonWidget
                      label={t('translation:requirements.4.form.q4.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
            </CustomModal>
        </div>
    );
};

export default RequirementsForm;
