import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import { t } from 'i18next';

type WidgetProps = {
    id: string,
    children?: ReactNode,
    onChange?: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void),
    value?: any,
    minWidth?: string,
    helperText?: string,
    name?: string,
    height?: string,
    object?: any,
    isCurrency?: boolean,
    isValue?: boolean,
    download?: () => void
};

const SelectWidget: FunctionComponent<WidgetProps> = (props: WidgetProps) => {
    return (
        // @ts-ignore
        <FormControl sx={{minWidth: props?.minWidth ? t('translation:errors.rem', {value: props.minWidth}) : '16.5rem'}}>
            <Select
                id={props.id}
                value={props.value || ''}
                onChange={props.onChange}
                name={props.name}
                sx={{
                    ml: 1.5,
                    height: props.height || '3.5rem',
                    minWidth: '6rem',
                }}
            >
                {props.object.map((v: any, index: number) =>
                    <MenuItem
                        key={index}
                        value={props.isValue ? v : index + 1}
                        onClick={() => props.download}
                    >
                        {props.isCurrency ? Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR'
                        }).format(v) : v}
                    </MenuItem>
                )}
            </Select>
            <FormHelperText>
                {props.helperText}
            </FormHelperText>
        </FormControl>
    );
};

export default SelectWidget;



